let deferredPrompt;
const installBanner = document.getElementById('installBanner');
const installButton = document.getElementById('installButton');
const dismissButton = document.getElementById('dismissButton');
const PWA_PROMPT_LAST_SHOWN = 'pwaPromptLastShown';
const PWA_INSTALLED = 'isPwaInstalled';
const DAYS_BEFORE_REPROMPT = 90;

function shouldShowPrompt() {
    const lastShown = localStorage.getItem(PWA_PROMPT_LAST_SHOWN);
    const isPwaInstalled = localStorage.getItem(PWA_INSTALLED);
    const currentTime = new Date().getTime();
    const ninetyDaysInMillis = DAYS_BEFORE_REPROMPT * 24 * 60 * 60 * 1000;

    if (isPwaInstalled === 'true') {
        return false;
    }

    if (!lastShown || (currentTime - lastShown) > ninetyDaysInMillis) {
        return true;
    }

    return false;
}

window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    deferredPrompt = e;

    if (shouldShowPrompt()) {
        if (installBanner) {
            installBanner.classList.remove('d-none');
        }
    }
});

if (installButton) {
    installButton.addEventListener('click', () => {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                localStorage.setItem(PWA_INSTALLED, 'true');
            } else {
                localStorage.setItem(PWA_INSTALLED, 'false');
            }
            localStorage.setItem(PWA_PROMPT_LAST_SHOWN, new Date().getTime());
            if (installBanner) {
                installBanner.classList.add('d-none');
            }
            deferredPrompt = null;
        });
    });
}

if (dismissButton) {
    dismissButton.addEventListener('click', () => {
        localStorage.setItem(PWA_PROMPT_LAST_SHOWN, new Date().getTime());
        if (installBanner) {
            installBanner.classList.add('d-none');
        }
    });
}

document.addEventListener('DOMContentLoaded', () => {
    if (!shouldShowPrompt()) {
        if (installBanner) {
            installBanner.classList.add('d-none');
        }
    } else {
        if (installBanner) {
            installBanner.classList.remove('d-none');
        }
    }
});
